/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, FC } from 'react';
import type { ServiceItem } from '@framework/api/types';
import { IconSpecialPromotions } from '@nzxt/react-icons';
import { useMedia, useTranslation as t } from '@utils/hooks';
import IconDynamic from '@components/IconDynamic';
import * as styles from './styles';

type Props = {
  slug: string;
  service?: ServiceItem | ServiceItem[];
  handleClick?: () => void;
};

const CardService: FC<Props> = ({ service }) => {
  const isSm = useMedia('sm');
  const LABEL_PROMO = t('label_promotion');
  const LABEL_PROMOS = t('label_promotions');
  const hasService = Array.isArray(service)
    ? service?.length > 0
    : Boolean(service?.copy);

  return hasService ? (
    <>
      {Array.isArray(service) && service?.length > 0 ? (
        <div className={styles.textBase}>
          {!isSm && <IconSpecialPromotions className={styles.icon} />}
          {service?.length > 1 && service?.length}{' '}
          {service?.length > 1 ? LABEL_PROMOS : LABEL_PROMO}
        </div>
      ) : (
        <div className={styles.textBase}>
          {!isSm && !Array.isArray(service) && service?.iconName && (
            <IconDynamic iconName={service.iconName} className={styles.icon} />
          )}
          {!Array.isArray(service) && service?.copy}
        </div>
      )}
    </>
  ) : null;
};
export default memo(CardService);
