import { classnames } from '@tailwindcss-classnames';

// eslint-disable-next-line import/prefer-default-export

export const base = classnames(
  'font-semibold',
  'text-xs',
  'py-1',
  'px-2',
  'inline-flex',
  'items-center',
  'rounded-md',
  'px-2',
  'py-1',
  'text-xs',
  'border'
);

export const outOfStock = classnames(
  'bg-nzxt-light-grey-200',
  'dark:bg-nzxt-dark-grey-500',
  'text-nzxt-light-grey-700',
  'dark:text-nzxt-dark-grey-50',
  'border-transparent'
);
export const lowStock = classnames(
  'bg-nzxt-yellow-200',
  'text-nzxt-brown-500',
  'border-transparent'
);
export const onSale = classnames(
  'bg-nzxt-green-400',
  'text-white',
  'border-transparent'
);
export const isPromo = classnames(
  'bg-nzxt-green-200',
  'text-nzxt-green-500',
  'border-transparent'
);
export const invalid = classnames('text-nzxt-red-400', 'border-nzxt-red-400');
export const newItem = classnames(
  'bg-nzxt-blue-400',
  'text-white',
  'border-transparent'
);
export const comingSoon = classnames(
  'bg-nzxt-blue-300',
  'text-white',
  'border-transparent'
);

export const bestSeller = classnames(
  'text-nzxt-teal-500',
  'bg-nzxt-teal-100',
  'border-transparent'
);
export const onClearance = classnames(
  'bg-nzxt-red-400',
  'text-white',
  'border-transparent'
);
export const isLimited = classnames(
  'bg-nzxt-red-400',
  'text-white',
  'border-transparent'
);
