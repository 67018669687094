import { FC, memo, useMemo } from 'react';
import cn from 'clsx';
import { useRouter } from 'next/router';
import { getRegion } from '@framework/api/utils/maxify';
import getAmountOffLabel from '@utils/get-amount-off-label';
import { MAXIFY_CURRENCY_MAP_PER_REGION } from '@constants';
import usePrice from '@framework/use-price';
import { useTranslation as t } from '@utils/hooks';
import type { Variant } from '@framework/api/types';
import * as styles from './styles';

type Props = {
  variant: Variant;
  isExpired?: boolean;
  isRegionallyAvailable: boolean;
};

// Set the price threshold for discount type: percent or amount off
const DISCOUNT_TYPE_THRESHOLD = 100;

export const getData = ({
  isValid,
  isLowStock,
  isOutOfStock,
  isOnSale,
  isComingSoon,
  isNewItem,
  isRegionallyAvailable,
  showSaleTag,
  isPromo,
  isExpired,
  showAmountOff,
  amountOff,
  isBestSeller,
  isOnClearance,
  isLimitedAvailability,
  basePrice,
}: {
  isValid: boolean;
  isLowStock: boolean;
  isOutOfStock: boolean;
  isOnSale: boolean;
  isComingSoon: boolean;
  isNewItem: boolean;
  isRegionallyAvailable?: boolean;
  isPromo?: boolean;
  showSaleTag?: boolean;
  showPercentOff?: boolean;
  percentOff?: number;
  isExpired?: boolean;
  showAmountOff?: boolean;
  amountOff?: number;
  isBestSeller?: boolean;
  isOnClearance?: boolean;
  isLimitedAvailability?: boolean;
  basePrice?: number;
}): { message: string; style: string; ampMessage?: string } => {
  if (!isRegionallyAvailable) {
    return null;
  }

  if (isComingSoon) {
    return { message: 'message_coming_soon', style: styles.comingSoon };
  }

  if (isOutOfStock || isExpired) {
    return { message: 'message_out_of_stock', style: styles.outOfStock };
  }

  if (isPromo) {
    return { message: 'message_promotion', style: styles.isPromo };
  }

  if (showAmountOff && amountOff) {
    return {
      message: null,
      style: styles.onSale,
      ampMessage: 'message_amount_off',
    };
  }

  if (basePrice < DISCOUNT_TYPE_THRESHOLD && amountOff) {
    return { message: 'discount_label', style: styles.onSale };
  }

  if (isOnClearance && isOnSale) {
    return { message: 'message_clearance', style: styles.onClearance };
  }

  if (isOnSale || showSaleTag) {
    return { message: 'message_on_sale', style: styles.onSale };
  }

  if (isLimitedAvailability) {
    return {
      message: 'message_is_limited_availability',
      style: styles.isLimited,
    };
  }

  if (isBestSeller && isOnClearance) {
    return { message: 'message_clearance', style: styles.onClearance };
  }

  if (isBestSeller) {
    return { message: 'message_best_seller', style: styles.bestSeller };
  }

  if (isOnClearance && isLowStock) {
    return { message: 'message_clearance', style: styles.onClearance };
  }

  if (isLowStock) {
    return { message: 'message_low_stock', style: styles.lowStock };
  }

  if (isOnClearance && isNewItem) {
    return { message: 'message_clearance', style: styles.onClearance };
  }

  if (isNewItem) {
    return { message: 'message_new_item', style: styles.newItem };
  }

  if (!isValid) {
    return { message: 'message_invalid', style: styles.invalid };
  }

  if (isOnClearance) {
    return { message: 'message_clearance', style: styles.onClearance };
  }

  return null;
};

const AvailabilityMessage: FC<Props> = ({
  variant,
  isExpired,
  isRegionallyAvailable,
}) => {
  const {
    isValid,
    isLowStock,
    isOutOfStock,
    isOnSale,
    isComingSoon,
    isNewItem,
    showSaleTag,
    isPromo,
    showPercentOff,
    percentOff,
    showAmountOff,
    amountOff,
    isBestSeller,
    isOnClearance,
    isLimitedAvailability,
    price: basePrice,
  } = variant;

  const router = useRouter();
  const { locale, defaultLocale } = router;
  const region = getRegion(locale);

  const currencyCode = useMemo(
    () => MAXIFY_CURRENCY_MAP_PER_REGION[region],
    [region]
  );

  const currentData = useMemo(
    () =>
      getData({
        isValid,
        isLowStock,
        isOutOfStock,
        isOnSale,
        isComingSoon,
        isNewItem,
        isRegionallyAvailable,
        showSaleTag,
        isPromo,
        showPercentOff,
        percentOff,
        isExpired,
        showAmountOff,
        amountOff,
        isBestSeller,
        isOnClearance,
        isLimitedAvailability,
        basePrice,
      }),
    [
      isValid,
      isLowStock,
      isOutOfStock,
      isOnSale,
      isComingSoon,
      isNewItem,
      isRegionallyAvailable,
      showSaleTag,
      isPromo,
      showPercentOff,
      percentOff,
      isExpired,
      showAmountOff,
      amountOff,
      isBestSeller,
      isOnClearance,
      isLimitedAvailability,
      basePrice,
    ]
  );

  const composedClassName = useMemo(
    () => cn(styles.base, currentData && currentData.style),
    [currentData]
  );

  const { price } = usePrice({
    amount: amountOff || 0,
    currencyCode,
  });

  const amountOffLabel =
    showAmountOff && amountOff
      ? getAmountOffLabel(locale, defaultLocale, price)
      : null;

  const id = currentData?.message ?? 'ok';
  const MESSAGE = t(id);
  const showAmount = showAmountOff && amountOffLabel;
  const showPercentOffLabel =
    isRegionallyAvailable && !isComingSoon && !isOutOfStock && !isExpired;

  const showAmountOffLabel =
    showAmount &&
    isRegionallyAvailable &&
    !isComingSoon &&
    !isOutOfStock &&
    !isExpired;

  const percentDiscount =
    isOnSale && percentOff
      ? percentOff
      : Math.round(
          (((variant?.price ?? 0) - (variant?.discounted_price ?? 0)) /
            (variant?.price || 1)) *
            100
        ) || 0;

  const chipLabel = (): string => {
    if (isPromo) {
      return MESSAGE;
    }

    if ((basePrice < DISCOUNT_TYPE_THRESHOLD && isOnSale) || showPercentOff) {
      if (showPercentOffLabel) {
        return `${percentDiscount}% ${t('discount_label')}`;
      }
    }

    if (showAmountOffLabel) {
      return amountOffLabel;
    }

    return MESSAGE;
  };

  return currentData ? (
    <p
      className={composedClassName}
      data-test-id="product-availability-message"
      suppressHydrationWarning
    >
      {chipLabel()}
    </p>
  ) : null;
};

export default memo(AvailabilityMessage);
