/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, FC, useMemo, Dispatch, SetStateAction } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation as t } from '@utils/hooks';
import GTM from '@utils/gtm';
import { ROUTE_PRODUCT } from '@constants';
import { getRegion } from '@framework/api/utils/maxify';
import type {
  DatoProductImage,
  DatoAvailableLocales,
  Variant,
  DatoProductSpecs,
  Services,
} from '@framework/api/types';
import QuickBuy from '@components/CardComponents/CardImage/QuickBuy';
import IconDynamic from '@components/IconDynamic';
import CardHeading from '../CardHeading';
import CardService from '../CardService';
import CardImage from '../CardImage';

import * as styles from './styles';

type Props = {
  name?: string;
  slug: string;
  productPrice?: number;
  variants?: Variant[];
  selectedVariant?: Variant;
  shortDescription?: string;
  collectionImage?: DatoProductImage;
  isNude?: boolean;
  availableRegions?: DatoAvailableLocales[];
  isExtendedCard?: boolean;
  techSpecs?: DatoProductSpecs[];
  noVariantToggle?: boolean;
  isSmall?: boolean;
  regionalServices?: Services;
  eventType?: string;
  isCustomCard?: boolean;
  setIsLoading?: Dispatch<SetStateAction<boolean>>;
  currencyCode?: string;
  hasBackground?: boolean;
  showCountdown?: boolean;
  rating?: number;
  isDark?: boolean;
};

const UpperCard: FC<Props> = ({
  currencyCode,
  name,
  slug,
  variants,
  shortDescription,
  collectionImage,
  isNude,
  availableRegions,
  isExtendedCard = false,
  isSmall = false,
  isCustomCard = false,
  regionalServices,
  eventType,
  selectedVariant,
  setIsLoading,
  hasBackground = false,
  showCountdown = false,
  rating,
  isDark = false,
}) => {
  const { locale } = useRouter();
  const region = getRegion(locale);

  const isRegionallyAvailable = useMemo(
    () =>
      Array.isArray(availableRegions)
        ? availableRegions.some(r => r.region === region)
        : false,
    [region, availableRegions]
  );

  const isProductAvailable =
    !selectedVariant.isComingSoon &&
    !selectedVariant.isOutOfStock &&
    selectedVariant?.isValid;

  const isAvailable = !isCustomCard
    ? isProductAvailable && !isNude && isRegionallyAvailable
    : true;

  const variantColorsTotal =
    Array.isArray(variants) && variants?.map(({ color }) => color);

  const useShipDate = process.env.NEXT_PUBLIC_ENABLE_SHIP_DATE === 'true';

  const COLORS = t('message_colors');
  const COLOR = t('message_color');
  const SHIPPING_LABEL = t('message_shipping_date');

  const colorText =
    variantColorsTotal?.length > 1
      ? `${variantColorsTotal?.length} ${COLORS}`
      : `${variantColorsTotal?.length} ${COLOR}`;

  const ecommerce = selectedVariant
    ? {
        click: {
          products: [
            {
              name,
              id: selectedVariant.id,
              price: selectedVariant?.price?.toString() ?? null,
              brand: 'NZXT',
              variant:
                selectedVariant?.color?.map(color => color.name).join('/') ??
                null,
            },
          ],
        },
      }
    : null;

  const handleEvent = (): void => {
    if (eventType) {
      GTM.dataLayer({
        dataLayer: {
          event: eventType,
          interactionType: 'productCard',
          ecommerce,
        },
      });
    }
  };

  const event = selectedVariant ? 'productClick' : 'clickToBuild';
  const handleLinkClick = (): void => {
    GTM.dataLayer({
      dataLayer: {
        event: typeof eventType !== undefined ? eventType : event,
        ecommerce,
      },
    });
  };

  const linkage = isCustomCard ? slug : `/${ROUTE_PRODUCT}/${slug}`;

  const hasPromo = Boolean(
    regionalServices?.shippingNotices ||
      regionalServices?.bundlesPromos?.length > 0
  );

  const showInventory =
    selectedVariant?.showInventoryCount &&
    isRegionallyAvailable &&
    !selectedVariant?.isComingSoon &&
    !selectedVariant?.isOutOfStock;

  return name && slug ? (
    <div className={styles.wrapper}>
      {process.env.NEXT_PUBLIC_FEATURE_ENABLE_QUICKSHOP !== 'true' &&
        isAvailable && (
          <div
            className={styles.quickBuyPlacement(isSmall)}
            style={{ zIndex: 1 }}
          >
            <QuickBuy
              selectedVariant={selectedVariant}
              currencyCode={currencyCode}
              name={name}
              setIsLoading={setIsLoading}
            />
          </div>
        )}
      <CardImage
        name={name}
        slug={slug}
        variants={variants}
        collectionImage={collectionImage}
        selectedVariant={selectedVariant}
        isRegionallyAvailable={isRegionallyAvailable}
        isProductAvailable={isProductAvailable}
        isAvailable={isAvailable}
        isSmall={isSmall}
        linkage={linkage}
        handleLinkClick={handleLinkClick}
        hasBackground={hasBackground}
        isDark={isDark}
      />
      <Link
        href={linkage}
        passHref
        onClick={() => handleLinkClick()}
        onKeyDown={() => handleLinkClick()}
        role="link"
        tabIndex={0}
      >
        <div className={styles.growWrapper}>
          <CardHeading
            showCountdown={showCountdown && selectedVariant?.isOutOfStock}
            name={name}
            slug={slug}
            shortDescription={shortDescription}
            hasBackground={hasBackground}
            isExtended={isExtendedCard}
            isSmall={isSmall}
            handleClick={handleEvent}
            inventoryData={{
              showInventory,
              stock: selectedVariant?.stock,
            }}
            rating={rating}
          />
        </div>
        {useShipDate && selectedVariant?.sla_date && isAvailable && (
          <div className={styles.slaContainer}>
            <IconDynamic
              iconName="IconFreeShipping"
              className={styles.slaIcon}
            />
            {SHIPPING_LABEL} {selectedVariant?.sla_date}
          </div>
        )}
        {!isExtendedCard && (
          <>
            {hasPromo ? (
              <div className={styles.servicesWrapper}>
                {isProductAvailable && regionalServices?.shippingNotices ? (
                  <CardService
                    slug={slug}
                    handleClick={handleEvent}
                    service={regionalServices?.shippingNotices}
                  />
                ) : null}
                {regionalServices?.bundlesPromos ? (
                  <CardService
                    slug={slug}
                    handleClick={handleEvent}
                    service={regionalServices?.bundlesPromos}
                  />
                ) : null}
              </div>
            ) : null}

            {isSmall && !isCustomCard && (
              <p className={styles.smallText(hasBackground)}>{colorText}</p>
            )}
          </>
        )}
      </Link>
    </div>
  ) : null;
};

export default memo(UpperCard);
