/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, FC } from 'react';
import { useTranslation as t } from '@utils/hooks';
import Rating from '@components/Rating';
import type { ProductRating } from '@framework/api/types';
import YotpoWidget from '@components/YotpoReviews/YotpoWidget';
import StockNotification from '../StockNotification';
import Notification from '../Notification';
import * as styles from './styles';

type Props = {
  name: string;
  slug: string;
  shortDescription?: string;
  isExtended?: boolean;
  isSmall?: boolean;
  handleClick?: () => void;
  inventoryData?: {
    showInventory: boolean;
    stock?: number;
  };
  showCountdown?: boolean;
  hasBackground?: boolean;
  rating?: number;
};

const CardHeading: FC<Props> = ({
  name,
  slug,
  shortDescription,
  rating,
  isExtended,
  isSmall = false,
  hasBackground = false,
  inventoryData,
  showCountdown = false,
}) => {
  const COUNTDOWN_NOTIFICATION = t('label_deal_sold_out');

  return (
    <div
      {...(isExtended ? { className: styles.container } : {})}
      style={{ flex: '0.5 0 0%', flexBasis: 'max-content' }}
    >
      <div>
        {name && (
          <p className={styles.heading(isSmall)} data-test-id="card-heading">
            {name}
          </p>
        )}
      </div>
      <div className={styles.shortDescription}>
        <p className={styles.descriptionColor(hasBackground)}>
          {shortDescription}
        </p>
      </div>
      <YotpoWidget type="star-rating" id={rating} template="collection" />
      {!showCountdown && (
        <StockNotification spaceTop inventoryData={inventoryData} />
      )}
      {showCountdown && (
        <Notification
          label={COUNTDOWN_NOTIFICATION}
          spaceTop
          alertColor="red"
        />
      )}
    </div>
  );
};

export default memo(CardHeading);
