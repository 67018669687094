/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, FC } from 'react';
import Link from 'next/link';
import cn from 'clsx';
import type { DatoProductImage, Variant } from '@framework/api/types';
import AvailabilityMessage from '@components/PDP/AvailabilityMessage';
import Image from '@components/Image';
import QuickShop from './QuickShop';
import css from './QuickShop.module.css';

import * as styles from './styles';

type Props = {
  name: string;
  slug: string;
  variants?: Variant[];
  collectionImage?: DatoProductImage;
  selectedVariant?: Variant;
  isRegionallyAvailable?: boolean;
  isProductAvailable?: boolean;
  isAvailable?: boolean;
  isCustomCard?: boolean;
  isSmall?: boolean;
  linkage?: string;
  handleLinkClick?: () => void;
  hasBackground?: boolean;
  isDark?: boolean;
};

const CardImage: FC<Props> = ({
  slug,
  variants,
  collectionImage,
  selectedVariant,
  isRegionallyAvailable,
  isProductAvailable,
  isSmall = false,
  name,
  linkage,
  handleLinkClick,
  hasBackground = false,
  isDark = false,
}) => {
  const image =
    selectedVariant?.images?.length > 0
      ? selectedVariant?.images[0]?.responsiveImage
      : null;

  return (
    <div
      className={cn(
        styles.imageWrapper(hasBackground, isDark),
        css.hoverWrapper
      )}
    >
      <Link
        href={linkage}
        passHref
        onClick={() => handleLinkClick()}
        onKeyDown={() => handleLinkClick()}
        role="link"
        tabIndex={0}
      >
        {Array.isArray(variants) && variants.length > 0 && selectedVariant && (
          <div className={styles.textWrapper}>
            {variants && (
              <AvailabilityMessage
                isRegionallyAvailable={isRegionallyAvailable}
                variant={selectedVariant}
              />
            )}
          </div>
        )}
        {selectedVariant?.collectionPageMainImage ? (
          <Image
            className={styles.image}
            data-test-id={`${slug}-image-link`}
            data={selectedVariant?.collectionPageMainImage?.responsiveImage}
            width={
              selectedVariant?.collectionPageMainImage?.responsiveImage?.width
            }
            height={
              selectedVariant?.collectionPageMainImage?.responsiveImage?.height
            }
          />
        ) : (
          <>
            {image ? (
              <Image
                className={styles.image}
                data-test-id={`${slug}-image-link`}
                data={image}
                width={image?.width}
                height={image?.height}
              />
            ) : (
              <Image
                className={styles.image}
                data-test-id={`${slug}-image-link`}
                data={collectionImage?.responsiveImage}
                width={collectionImage?.responsiveImage?.width}
                height={collectionImage?.responsiveImage?.height}
              />
            )}
          </>
        )}
      </Link>

      {process.env.NEXT_PUBLIC_FEATURE_ENABLE_QUICKSHOP === 'true' &&
        Array.isArray(variants) &&
        variants.length > 0 &&
        selectedVariant &&
        isProductAvailable && (
          <QuickShop
            name={name}
            selectedVariant={selectedVariant}
            isSmall={isSmall}
            slug={slug}
          />
        )}
    </div>
  );
};

export default memo(CardImage);
