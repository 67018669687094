/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, FC } from 'react';
import Link from 'next/link';
import type { Variant } from '@framework/api/types';
import { useTranslation as t, useMedia } from '@utils/hooks';
import { ROUTE_PRODUCT } from '@constants';
import ColorSelectButton from '@components/ColorSelectButton';
import * as styles from './styles';

type Props = {
  setSelectedVariant?: (variant: Variant) => void;
  selectedVariant: Variant;
  isRegionallyAvailable?: boolean;
  variants: Variant[];
  productName?: string;
  slug?: string;
  isFaux?: boolean;
  isSmall?: boolean;
  handleClick?: () => void;
  hasBackground?: boolean;
};

const CardColorVariants: FC<Props> = ({
  isRegionallyAvailable,
  variants,
  selectedVariant,
  setSelectedVariant,
  productName,
  isFaux = false,
  slug,
  isSmall = false,
  handleClick,
  hasBackground = false,
}) => {
  const isMoreThanThree = Array.isArray(variants) && variants.length > 3;
  const colorsSlicedVariants = Array.isArray(variants) && variants?.slice(0, 3);
  const variantOptions = isMoreThanThree ? colorsSlicedVariants : variants;
  const remainingVariantAmount = isMoreThanThree
    ? Array.isArray(variants) && variants.length - 3
    : 0;
  const COLOR_OPTIONS_LABEL = t('color_options');
  const isSm = useMedia('sm');

  return (
    <div className={styles.optionsGrid} data-test-id="card-color-variant-block">
      {Array.isArray(selectedVariant.color) &&
        selectedVariant.color.length >= 1 && (
          <div
            className={styles.colorSelectItem}
            {...(isFaux && {
              role: 'listbox',
              'aria-label': COLOR_OPTIONS_LABEL,
            })}
          >
            <ColorSelectButton
              setVariant={setSelectedVariant}
              variants={variantOptions}
              selectedVariant={selectedVariant}
              isRegionallyAvailable={isRegionallyAvailable}
              productName={productName || 'empty'}
              slug={slug}
              isFaux={isFaux}
              smallSelects={isSmall}
              handleClick={handleClick}
            />
            {!isSm && isMoreThanThree && remainingVariantAmount > 0 && (
              <Link
                href={`/${ROUTE_PRODUCT}/${slug}`}
                passHref
                className={styles.lightGreyCopy(hasBackground)}
                role="link"
                tabIndex={0}
                onClick={handleClick ? () => handleClick() : null}
                onKeyDown={handleClick ? () => handleClick() : null}
              >
                +{remainingVariantAmount}
              </Link>
            )}
          </div>
        )}
    </div>
  );
};

export default memo(CardColorVariants);
